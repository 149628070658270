import React from 'react';


function Cur95(){
    return (
        <div style={{padding:'25px 0 25px 0px', background:'whitesmoke'}}>
            <img src='images/cur95.jpg' alt='img' className='popular-product-img'/>
        </div>
    )
}

export default Cur95;