import React from 'react';
import '../../css-files/about-us.css'

class AboutUsComponent extends React.Component{
    render(){
        return(
            <div className="about-us-container">
                <h5 className="about-us-heading">About us : </h5>
                <p className="about-us-para">Phyto Ingredients Biopharma Pvt. Ltd. has been established by Young 
                enthusiast experienced professionals having sound experience in the field
                 of research, development & manufacturing of quality Active pharmaceuticals
                ingredients, standardized Herbal Extracts.</p>
                <p className="about-us-para">Phyto Ingredients Biopharma is constantly exercising critical thinking
                     to maintain professional competence for excelling the quality of its
                      products. Phyto Ingredients Biopharma will always embrace highest
                    standard of character to serve and promote quality of life through quality product with higher performance.</p>
                    <p className="about-us-para">Starting as a producer and supplier of essential oils, dried herbs,
                         herbal extracts of various herbs & Active pharmaceuticals aims to
                          be a leading manufacturer of herbal based end products as well 
                          as world class herbal cosmetics, food supplements, essential oils, 
                          extracts and GMP certified herbal medicines.</p>
                <h5 className="about-us-heading">Our Mission : </h5>
                <p className="about-us-para">At Phyto Ingredients Biopharma Pvt. Ltd., we have been utilizing mother 
                nature using extensive experience about crucial medicinal plants. Our primary mission has been to
                 explore produce use & provide natural products manufactured by world-class technologies and adhering
                  with the compliance to regulatory authorities and international standards for quality.</p>
                <p className="about-us-para">Our aim is to provide consumers with Highly Affordable, Technologically Advanced,
                 and High Quality Formulations and Natural Products that are also capable to benefit the end customer. We want 
                 our partners to enjoy a competitive edge in their respective markets with our products. We also guarantee their
                  profitability throughout our partnership.</p>
                    <p className="about-us-para">Our ultimate aim is to give our best share in help and serve the humanity and
                     to make the healthy world with quality products on the basis of highest ethical values and integrity.</p>
                     <h5 className="about-us-heading">Our Vision : </h5>
                <p className="about-us-para">We aim towards become the most reliable and renowned name in the world to supply
                 Herbal Extracts, Active pharmaceutical ingredients, bulk drugs, Raw herbs, herbal formulations, organic raw 
                 herbs, pharmaceutical chemicals, essential oils and guidance as consultancy towards plant & equipment designing
                  and Biopharmaceuticals process development and scale up.</p>
                <p className="about-us-para">We aspire to offer 100 percent satisfaction to our esteemed clientele at all levels.
                    We want to create innovative and revolutionary products to be recognized as world leaders to serve our clients better.</p>
            </div>
        )
    }
}

export default AboutUsComponent;