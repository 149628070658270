import React from 'react';
import '../../css-files/header.css';

class Header extends React.Component {

    render(){
        return(
            <div className='header-2'>
            </div>
        )
    }
}

export default Header;