import React from 'react';

class ProjectManagement extends React.Component{
render(){
    return (
        <div className='product-dev-container' >
            <h5>CONSULTANT FOR PROJECT MANAGEMENT</h5>
            <p>We offer the Design Engineering, Project Management in totality or individually as per the specific requirements of our valued Clients.</p>
            <p>This is broadly divided into following services:</p>
            <ul>
                <li>Design and execution</li>
                <li>DeCivil & Structuralsign</li>
                <li>Clean Rooms</li>
                <li>HVAC</li>
                <li>Process Equipment’s</li>
                <li>DeElectricalsign</li>
                <li>Process Piping</li>
                <li>Utility Piping</li>
                <li>Fire system</li>
                <li>SS equipment’s and accessories</li>

            </ul>
        </div>
    )
}

}

export default ProjectManagement;

