import React from 'react';

class ProjectProductDevelopement extends React.Component{
render(){
    return (
        <div className='product-dev-container' >
            <h5>CONSULTANT FOR PRODUCT DEVELOPMENT</h5>
            <p>We at Phyto ingredients Biopharma Pvt. Ltd. having well qualified and experienced team for the product development.</p>
            <p><strong>YOUR GOAL:</strong> To create value for your company by offering innovative products that solve customer problems.</p>
            <p><strong>OUR GOAL:</strong> To help you enrich your portfolio with products that yield bottom-line growth.</p>
        </div>
    )
}

}

export default ProjectProductDevelopement;