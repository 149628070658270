import React from 'react';
import '../../css-files/about-us.css';

class Infrastructure extends React.Component{
    render(){
        return(
            <div className="buisness-develeopement-container">
                <h2 >Coming Soon....</h2>
                {/* <h5 className="buisness-dev-heading"  className="business-heading">Our Infrastructure: </h5>
                <div className="infra-con">
                    <div className="infra1-container" >
                        <div>QA Department</div>
                        <img src="/images/infra1.jpeg" />
                    </div>
                    <div className="infra2-container" >
                        <img src="/images/infra2.jpeg" />
                        <div>Manufacturing Facility</div>
                    </div>
                    <div className="infra1-container" >
                        <div>Quality Control Department</div>
                        <img src="/images/infra3.jpeg" />
                    </div>
                    <div className="infra2-container" >
                        <img src="/images/infra4.jpeg" />
                        <div>Finished Goods Storage Area</div>
                    </div>

                </div> */}
            </div>
        )
    }
}

export default Infrastructure;