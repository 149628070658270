import React from 'react';


function Cur30(){
    return (
        <div style={{padding:'25px 0 25px 0px', background:'whitesmoke'}}>
            <img src='images/nano.png' alt='img' className='popular-product-img'/>
        </div>
    )
}

export default Cur30;